<template>
  <div class="container">
    <div @click="goback" style="font-size:14px;margin-bottom:10px;cursor: pointer;width:50px">
      <i class="el-icon-back"></i>
      返回
    </div>
    <el-form :rules="rules" ref="form" :model="form" label-width="150px" style="width:500px">
        <el-form-item prop="name" label="app名称">
            <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item prop="app_secret" label="app秘钥">
            <el-input v-model="form.app_secret"></el-input>
        </el-form-item>
        <el-form-item prop="descr" label="app描述">
            <el-input v-model="form.descr"></el-input>
        </el-form-item>
        <el-form-item prop="android_package" label="安卓包名">
            <el-input v-model="form.android_package"></el-input>
        </el-form-item>
        <el-form-item prop="ios_package" label="ios包名">
            <el-input v-model="form.ios_package"></el-input>
        </el-form-item>
        <el-form-item prop="page_code" label="内容页code集合">
           <el-select v-model="form.page_code" multiple>
            <el-option
              v-for="item in contentCode"
              :key="item.code"
              :label="item.name"
              :value="item.code">
            </el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item prop="private_key" label="私钥">
            <el-input v-model="form.private_key"></el-input>
        </el-form-item>
        <el-form-item prop="public_key" label="公钥">
            <el-input v-model="form.public_key"></el-input>
        </el-form-item> -->
        <el-form-item prop="share_count" label="允许分享人数">
            <el-input-number
              v-model="form.share_count"
              :min="1"
            ></el-input-number>
        </el-form-item>
        <el-form-item prop="verification_code_length" label="验证码长度">
          <el-input-number
              v-model="form.verification_code_length"
              :min="1"
            ></el-input-number>
        </el-form-item>
        <el-form-item prop="show_type" label="目录结构展示方式">
          <el-select v-model="form.show_type">
            <el-option label="按功能架构方式展示" :value="1"></el-option>
            <el-option label=" 自定义展示" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
            <el-button type="primary" @click="Validate(onSubmit)">提交</el-button>
        </el-form-item>
    </el-form>
    
  </div>
</template>

<script type="text/ecmascript-6">
export default {
  name: '',
  mixins: [],
  components: {},
  props: {},
  data () {
    var checkSecret = (rule, value, callback) => {
      if (value == '') {
        callback()
      }
      let reg = /^[0-9a-zA-Z]{32}$/
      if (!reg.test(value)) {
        callback(new Error('请输入长度为32位的密钥'))
      } else {
        callback()
      }
    }
    return {
      form: {
        code: '',
        android_package: '',
        app_secret: '',
        descr: '',
        ios_package: '',
        name: '',
        page_code: '',
        private_key: '',
        public_key: '',
        share_count: 1,
        show_type: '',
        verification_code_length: 1
      },
      rules: {
        verification_code_length: [{ required: true, message: '请输入安卓包名', trigger: 'blur' }],
        android_package: [{ required: true, message: '请输入安卓包名', trigger: 'blur' }],
        app_secret: [{ required: false, validator: checkSecret, trigger: 'blur' }],
        descr: [{ required: true, message: '请输入app描述', trigger: 'blur' }],
        ios_package: [{ required: true, message: '请输入ios包名', trigger: 'blur' }],
        name: [{ required: true, message: '请输入app名称', trigger: 'blur' }],
        page_code: [{ required: true, message: '请输入内容页code集合', trigger: 'blur' }],
        // private_key: [{ required: true, message: '请输入私钥', trigger: 'blur' }],
        // public_key: [{ required: true, message: '请输入公钥', trigger: 'blur' }],
        share_count: [{ required: true, message: '请输入允许分享人数', trigger: 'blur' }],
        show_type: [{ required: true, message: '请输入目录结构展示方式', trigger: 'blur' }],
      },
      contentCode: [],
      record_code: ''
    }
  },
  computed: {},
  watch: {},
  methods: {
    GetInfo () {
      this.api.ClientDetail({
        code: this.record_code
      }).then(res => {
        if (res.data.code == 200) {
          this.form = res.data.data
          this.form.page_code = JSON.parse(res.data.data.page_code)
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    GetContent(){
      this.api.ContentList({
        page: 1,
        limit: 100,
        kname: '',
        code: ''
      }).then(res => {
        if (res.data.code == 200) {
          this.contentCode = res.data.data.data
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    goback (){
      this.$router.push('/iot/app/appvesion')
    },
    Validate: function (call) {
      this.$refs['form'].validate(res => {
        if (res && call) {
          call()
        }
      })
    },
    onSubmit (){
      this.api.ClientUpdate({
        code: this.form.code,
        android_package: this.form.android_package,
        app_secret: this.form.app_secret,
        descr: this.form.descr,
        ios_package: this.form.ios_package,
        name: this.form.name,
        page_code: this.form.page_code,
        private_key: this.form.private_key,
        public_key: this.form.public_key,
        share_count: +this.form.share_count,
        show_type: +this.form.show_type,
        verification_code_length: +this.form.verification_code_length,
      }).then(res => {
        if (res.data.code == 200) {
          this.form = {}
          this.$confirm('提交成功，是否返回列表页?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.goback()
          })
          return
        }
        this.$message.error('提交失败' + res.data.msg)
      })
    }
  },
  filters: {},
  mounted () {
    this.record_code = this.$route.params.code
    if (this.record_code !== '0') {
      this.GetInfo()
    }
  },
  created () {
    this.GetContent()
  }
}
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
</style>
